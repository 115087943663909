import React, { useState } from "react"
import { Container, Form, Col, Row, Button, Alert } from "react-bootstrap"
import api from "../utils/api"
import LoadingOverlay from "react-loading-overlay"
import "bootstrap/dist/css/bootstrap.min.css"

export default function Request() {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const sendRequest = async () => {
    setError("")
    setSuccess("")
    if (!email) {
      return setError("Email is required")
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      return setError("Invalid email")
    } else {
      try {
        setLoading(true)
        await api().post(`/users/password-reset`, { email })
        setSuccess(`Sent password reset link to ${email}`)
        setLoading(false)
      } catch (error) {
        const message = error.response.data.message
        setLoading(false)
        setError(message)
      }
    }
  }
  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }} style={{ marginTop: "100px" }}>
          <h1 className="text-center">Reset your password</h1>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                Enter your user account's verified email address and we will
                send you a password reset link.
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>
            <LoadingOverlay active={loading} spinner>
              <Button
                style={{ width: "100%" }}
                variant="primary"
                type="button"
                onClick={sendRequest}
                disabled={!email}
              >
                Send
              </Button>
            </LoadingOverlay>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
